<template>
  <div class="no_touch">
    <!--  睡眠监测  -->
    <div class="contr-content">
      <div class="contr-date">
        <img class="img" @click="leftDay" src="../assets/app_arrow_left.png" alt=""/>
        <div @click="showCalendar" style="margin-left: 10px; margin-right: 10px">
          <div style="font-size: 20px">星期{{ '日一二三四五六'.charAt(currentDate.getDay()) }}</div>
          <div>{{ currentDate.getFullYear() }}-{{ currentDate.getMonth() + 1 }}-{{ currentDate.getDate() }}</div>
        </div>
        <img class="img" @click="rightDay" src="../assets/app_arrow_right.png" alt=""/>
      </div>
      <pie-chart v-if="loaded" :chart-data="chartData" :options="chartOption"/>

      <div class="contr-time">
        <div class="text-title">
          <div>开始时间</div>
          <div class="text-value">{{ startDate }}</div>
        </div>
        <div class="text-title">
          <div>结束时间</div>
          <div class="text-value">{{ endDate }}</div>
        </div>
      </div>
      <div class="contr-time">
        <div class="text-title">
          <div>深睡</div>
          <div class="text-value">{{ startTime }}</div>
        </div>
        <div class="text-title">
          <div>浅睡</div>
          <div class="text-value">{{ endTime }}</div>
        </div>
      </div>
    </div>

    <van-popup v-model="showPopup" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="confirmDate"
          :min-date="minDate"
          :max-date="maxDate"
      />
    </van-popup>
  </div>
</template>

<script>
import {post} from "@/router/httpUtils";
import PieChart from "@/components/PieChart";
import {APP_ID, Language, State_0, TimeOffset} from "@/constants/contants";
import {Toast} from "vant";
// import {str2Date} from "@/util/timeUtil";

export default {
  name: "Sleep",
  components: {
    PieChart
  },
  data() {
    return {
      currentDate: new Date(new Date().getTime() -24*60*60*1000),
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2100, 12, 31),
      showPopup: false,
      loaded: false,
      startTime: '',
      endTime: '',
      startDate: '',
      endDate: '',
      chartData: {
        labels: ['无数据 100.0%'],
        datasets: [{
          //#2B018B
          backgroundColor: ['#C8C8C8', '#67BFE3'],
          data: [100, 0]
        }],
      },
      chartOption: {
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  mounted() {
    this.handleDate(this.currentDate)
  },
  methods: {
    showCalendar() {
      this.showPopup = true
    },
    leftDay() {
      const newDate = this.currentDate.setDate(this.currentDate.getDate() - 1)
      this.currentDate = new Date(newDate)
      this.handleDate(this.currentDate)
    },
    rightDay() {
      const newDate = this.currentDate.setDate(this.currentDate.getDate() + 1)
      this.currentDate = new Date(newDate)
      this.handleDate(this.currentDate)
    },
    confirmDate(date) {
      this.showPopup = false
      this.currentDate = date
      this.handleDate(date)
    },
    handleDate(date) {
      const start = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate()) + ' 20:00:00'
      var temp = new Date(date)
      temp.setTime(date.getTime() + 24*60*60*1000)
      const end = temp.getFullYear() + '-' + (temp.getMonth() + 1) + '-' + (temp.getDate()) + ' 12:00:00'
      this.getSleepInfo(start, end)
    },
    getSleepInfo(start, end) {
      this.loaded = false
      post('/api/Health/GetHealth', {
        "AppId": APP_ID,
        "DeviceId": localStorage.DeviceId,
        "EndTime": end,
        "Language": Language,
        "StartTime": start,
        "TimeOffset": TimeOffset,
        "Token": localStorage.Token
      }).then(res => {
        console.log(res)
        if (res.State != State_0) {
          Toast.fail({
            message: '网络连接错误'
          })
          this.loaded = true
          return
        }
        // chartData.datasets[0]
        const items = res.SleepItems
        if (items.length === 0 || items[0].TotalSec === 0) {
          this.chartData = {
            labels: ['无数据 100.0%'],
            datasets: [{
              backgroundColor: ['#C8C8C8', '#67BFE3'],
              data: [100, 0]
            }],
          }
          this.startDate = '无数据'
          this.endDate = '无数据'

          this.endTime = '无数据'
          this.startTime = '无数据'
        } else {
          var deepSleepSec = 0
          var lightSleepSec = 0
          var totalSec = 0
          for (var i=0,len=items.length; i<len; i++) {
            deepSleepSec += items[i].DeepSleepSec
            lightSleepSec += items[i].LightSleepSec
            totalSec += items[i].TotalSec
          }

          const deepRate = (deepSleepSec * 100 / totalSec).toFixed(1)
          const lightRate = (lightSleepSec * 100 / totalSec).toFixed(1)
          this.chartData = {
            labels: ['深睡 ' + deepRate + '%', '浅睡 ' + lightRate + '%'],
            datasets: [{
              backgroundColor: ['#2B018B', '#67BFE3'],
              data: [deepRate, lightRate]
            }],
          }

          this.startDate = items[0].StartTime.split("T")[1].substring(0,5);
          this.endDate = items[items.length - 1].EndTime.split("T")[1].substring(0,5);

          this.startTime = (deepSleepSec / 60 / 60).toFixed(2) + '小时'
          this.endTime = (lightSleepSec / 60 / 60).toFixed(2) + '小时'

        }
        this.loaded = true
      })
    }
  }
}
</script>

<style scoped>
.contr-content {
  max-width: 600px;
  margin: 15px auto;
}

.contr-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.img {
  width: 30px;
  height: 30px;
}

.contr-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: gray;
  margin: 20px 60px;
}

.text-title {
  width: 80px;
  text-align: center;
}

.text-value {
  color: black;
  margin-top: 10px;
}
</style>